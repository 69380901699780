@import '../../../styles/mixins.scss';

.loading {
  @include flex($justifyContent: center);
  width: 100%;

  .spinner {
    animation-name: spin;
    animation-duration: 700ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
