@import '../../styles/mixins.scss';

.clusterIcon {
  @include size(2rem, 2rem);
  @include container($borderRadius: 50%, $backgroundColor: $white);
  @include flex($justifyContent: center);
  @include font($fontWeight: 900, $fontSize: 1.5rem); 
  transform: translate(-25%, -25%);

  &:hover {
    cursor: pointer;
    background-color: $primaryLight50;
    border-color: $primary;
  }
}
