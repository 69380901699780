@import '../../styles/mixins.scss';

$containerPadding: 1rem;

.dropdown {
  @include size(100%, 100%);
  position: relative;

  .topContainer {
    @include container($borderRadius: $radiusSmall, $padding: $containerPadding, $border: 2px solid, $borderColor: $neutralLight10);
    @include flex($alignItems: flex-start);
    @include size(100%, 100%);
    text-align: left;
    cursor: pointer;
    // z-index so that the box-shadow doesn't get cut off by the dropped list
    position: relative;
    z-index: 11;

    .title {
      @include font();
      color: $neutralLight10;
    }

    .info {
      @include flex($flexDirection: column, $alignItems: flex-start);
      text-align: left;
      width: 100%;
    }

    .imgContainer {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        // 50% of the parent's height, the plus $containerPadding is to use 50% of the parent's padding
        height: calc(50% + $containerPadding);
      }
    }

    &.disabled {
      background-color: $neutralLight20;
      pointer-events: none;
    }

    &.pill {
      border-radius: 100vh;
    }
  }

  .droppedContainer {
    @include size(100%, 50%);
    position: absolute;
    top: 50%;

    .droppedTopBackground {
      @include container($borderRadius: 0, $borderColor: $neutralLight10, $border: 2px solid, $boxShadow: none);
      @include size(100%, 100%);
      border-bottom: none;
      background-color: $white;
    }

    .dropedList {
      @include container($borderRadius: 0 0 $radiusMedium $radiusMedium, $borderColor: $neutralLight10, $border: 2px solid, $boxShadow: none);
      @include flex($flexDirection: column, $gap: 0);
      border-top: none;
      width: 100%;
      position: absolute;
      z-index: 10;

      .optionContainer {
        width: 100%;
      }
    }
  }

  &.disabled {
    background-color: $neutralLight10;
    cursor: not-allowed;
  }
}

.searcher {
  padding-top: 1rem;
}

.item {
  padding: 0 !important;
  margin: 0 !important;

  &:global(.Mui-selected) {
    background-color: unset !important;
  }

  .control {
    width: 100%;
    margin: 0 !important;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
  }
}

.stickyBottom {
  padding: 1rem;
  position: sticky !important;
  bottom: 0;
  margin: 0 !important;
  background-color: white;
  border-top: 1px solid $secondaryDark30;
  @include flex($justifyContent: flex-end);

  > button {
    text-transform: unset !important;
  }
}

.filled {
  fieldset {
    border-color: $primary !important;
    border-width: 2px;
  }

  :global(.MuiSvgIcon-root):not(.clearIcon) {
    display: none;
  }

  :global(.MuiSelect-select) {
    padding-right: 1rem !important;
  }
}

.displayBox {
  @include flex($justifyContent: space-between);
  color: $primary;
}

.clearButton {
  background-color: unset;
  border: unset;
  color: $secondaryDark50;
  display: flex;

  &:hover:not(:disabled) {
    cursor: pointer;
    color: $primary;
  }
}

.nativeSelect {
  flex-grow: 1;
}

.nativeClear {
  padding: 0 !important;
  min-width: 2.5rem !important;

  :global(.MuiCircularProgress-root) {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
}
