@import '../../styles/mixins.scss';

.tabIndicator {
  @include flex($justifyContent: space-between);
  border-bottom: 1px solid;
  border-color: $neutral;
  margin-top: 1rem;

  .tabs {
    @include flex($gap: 0.5rem);

    .tab {
      @include flex($justifyContent: center);
      @include font($fontWeight: 500, $fontSize: 1rem);
      padding: 0.5rem 1.5rem;
      height: 2.5rem;
      outline: none;
      appearance: none;
      cursor: pointer;
      background-color: transparent;
      border: none;

      &:hover:enabled {
        border-bottom: 2px solid;
        border-color: $primaryLight30;
        color: $primaryLight30;

        &:focus {
          color: $primaryLight10;
          border-color: $primaryLight10;
        }
      }

      &:disabled {
        color: $neutralLight20;
        border-color: $neutralLight20;
        cursor: not-allowed;
      }

      &.tabActive {
        border-bottom: 2px solid;
        color: $primary;
      }
    }
  }

  &.secondaryTabIndicator {
    margin-top: 0;
    border-left: 1px solid $neutral;
  }
}
