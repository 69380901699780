@import '../../../../styles/mixins.scss';
@import '../Sidebar.module.scss';

.sidebarItem {
  @include flex();
  @include container($borderRadius: 0, $boxShadow: 0, $padding: 0.75rem 1.75rem, $border: 0);
  @include font($fontFamily: $redHatDisplay, $fontWeight: 500, $fontSize: 1.25rem);
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  border-right: 3px solid $white;
  transition: all $transitionTime ease-in-out;

  p {
    color: transparent;
    white-space: nowrap;
    transition: color $transitionTime ease-in-out;

    &.open {
      color: $neutralDark40;
    }
  }

  &:hover,
  &.active {
    background-color: $primaryLight50;
    transition: background-color $transitionTime ease-in-out;

    img {
      filter: $filterPrimaryLight10;
    }

    p {
      &.open {
        color: $primaryLight10;
      }
    }
  }

  &:hover {
    border-right: 3px solid $primaryLight50;
  }

  &.active {
    border-right: 3px solid $primaryLight10;
  }
}
