@import '../../styles/mixins.scss';

.scroll {
  @include container($backgroundColor: $secondaryDark10);
  width: 100%;

  .infinityScroll {
    width: 100%;

    .item {
      width: 100%;
    }

    .finalText {
      text-align: center;
    }
  }

  &.dropdown {
    background-color: $white;
  }
}
