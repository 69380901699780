@import '../../../styles/mixins.scss';

.step2 {
  @include flex($flexDirection: column, $alignItems: flex-start, $gap: 2rem);
  width: 100%;

  .filters {
    @include flex();
    @include size(100%, 5rem)
  }

  .stores {
    @include flex($justifyContent: space-between);
    width: 100%;

    .storesColumn {
      @include flex($flexDirection: column);
      width: 33%;

      .storesHeader {
        @include size(100%, 3rem);
        @include flex($justifyContent: space-between);

        .pill {
          @include container($borderRadius: 2.5rem, $backgroundColor: $primaryLight50, $padding: 0.5rem 1rem, $border: none);
          color: $primaryDark10;
        }
      }

      .storesBody {
        @include flex($flexDirection: column, $gap: 0.5rem);
        width: 100%;

        .selectAllContainer {
          @include size(100%, 4rem);
        }
      }

      .mapContainer {
        @include container($borderRadius: $radiusBig, $border: 3px solid, $borderColor: $secondary);
        @include size(100%, 38rem);
        overflow: hidden;
      }
    }
  }

  .pickers {
    @include flex($justifyContent: space-between);
    width: 100%;
  }
}
