@import '../../../styles/mixins.scss';

.footer {
  @include flex();
  @include size(100%, $footerHeight);
  border-top: 0.5px solid $neutralDark10;

  .brandsAndNinjas {
    @include link($isUnderlined: false);
  }

  .links {
    @include flex($justifyContent: flex-end, $gap: 1.5rem);
    width: 100%;

    .rightsReserved,
    .link {
      @include font($redHatDisplay, normal, 300, 0.75rem, 120%, 0em, $neutralDark10);
    }

    .link {
      @include link($color: $neutralDark10, $colorHover: $black, $isUnderlined: false);
    }
  }
}
