@import '../../styles/mixins.scss';

.validation {
  @include flex($alignItems: stretch);
  width: 100%;

  .id {
    @include flex($justifyContent: center);
    @include container($backgroundColor: $primaryLight40);
    color: $white;
    width: 3rem;

    .idText {
      transform: rotate(-90deg);
    }
  }

  .validationTable {
    @include flex($flexDirection: column, $alignItems: stretch, $gap: 0);
    @include container($border: 2px solid, $borderColor: $neutral);
    flex: 1;

    .validationTableRow {
      @include flex($alignItems: stretch, $gap: 0);
      flex-wrap: wrap;

      .validationHalfRow {
        @include flex($alignItems: stretch, $gap: 0);
        flex: 1;
        min-width: 22rem;
        border-top: 1px solid $neutralLight20;

        &:first-child {
          border-right: 2px solid $neutralLight20;
        }
      }

      .title {
        width: 16rem;
        padding: 1rem;
        border-right: 2px dotted $neutralLight20;
      }

      .info {
        @include flex($gap: 0);
        padding: 1rem;
        flex: 1;
      }
    }
  }
}
