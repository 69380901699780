@import '../../styles/mixins.scss';

.percentageBar {
  @include size(100%, 2rem);
  @include container($borderRadius: 1rem, $backgroundColor: $primaryLight50, $border: 2px solid, $borderColor: $primary);
  overflow: hidden;
  max-width: 50rem;
  position: relative;

  .progressBar {
    @include container($borderRadius: 1rem, $backgroundColor: $primary, $border: 0);
    @include flex($flexDirection: column, $justifyContent: center, $alignItems: flex-end);
    height: calc(2rem - 4px);
    position: absolute;
    z-index: 2;

    .percentage {
      margin-right: 0.5rem;
      color: $white;

      &.outside {
        margin-right: -1.5rem;
        color: $black
      }
    }

    &.second {
      background-color: $primaryLight30;
      z-index: 1;
    }
  }
}
