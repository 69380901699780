@import '../../styles/mixins.scss';

.questionStatisticCard {
  @include flex($flexDirection: column, $alignItems: flex-start);
  padding: 1.5rem;

  .expectedAnswer {
    @include flex($alignItems: flex-end);

    .italic {
      font-style: italic;
      color: $neutral;
    }
  }

  .answers {
    width: 100%;
    height: 15rem;
    display: flex;

    .answer {
      width: 100%;

      span {
        margin-left: 0.5rem;
        margin-bottom: 5rem;
      }
    }
  }
}
