@import '../../styles/mixins.scss';

.filters {
  @include flex();
  height: 3rem;
  margin-top: 1rem;
}

.noAnswers {
  margin-top: 5rem;
  text-align: center;
}

.missionsList {
  @include flex($flexDirection: column);
  margin-top: 1rem;
}
