@import '../../styles/mixins.scss';

.partial {
  @include flex($justifyContent: space-between, $alignItems: stretch);
  margin: 2rem 0;

  .id {
    @include flex($justifyContent: center);
    @include container($backgroundColor: $primaryLight10);
    width: 3rem;
    color: $white;

    .idText {
      transform: rotate(-90deg);

      &.reversed {
        transform: rotate(90deg);
      }
    }

    &.secondary {
      background-color: $primaryLight30;
    }
  }

  .halfPartial {
    @include flex($alignItems: stretch);
    min-width: 20rem;
    flex: 1;

    .info {
      @include flex($flexDirection: column, $alignItems: flex-start);
      flex: 1;

      .infoPartial {
        @include flex($flexDirection: column, $alignItems: flex-start, $gap: 0.25rem);
      }

      &.reversed {
        align-items: flex-end;

        .infoPartial {
          align-items: flex-end;
          text-align: right;
        }
      }
    }
  }

  .infoQuestions {
    @include flex($flexDirection: column, $gap: 1.5rem);
    flex: 1;
  }

  .stores {
    @include flex($flexDirection: column, $alignItems: stretch);
    flex: 1;

    .store {
      @include flex();
    }

    .idStore {
      @include flex($flexDirection: column);
      @include container($backgroundColor: $primaryLight40);
      width: 3rem;
      color: $white;
    }
  }

  .mapContainer {
    @include container($borderRadius: $radiusBig, $border: 3px solid, $borderColor: $secondary);
    @include size(50%, 38rem);
    flex: 1;
    min-width: 26rem;
    overflow: hidden;
    position: sticky;
    top: 1rem;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.datesHeader {
  @include flex($gap: 0rem, $alignItems: flex-end);
  padding: 1rem 0;

  .date {
    flex: 1;
    text-align: center;
    padding: 0 1rem;

    .italic {
      font-style: italic;
      color: $neutral;
    }
  }
}

.datesDots {
  @include flex($justifyContent: space-around);
  position: relative;

  .dot {
    @include size(1rem, 1rem);
    border-radius: 100%;
    background-color: $black;

    &.neutral {
      background-color: $neutral;
    }
  }

  .lines {
    @include flex($gap: 0rem);
    @include size(100%, 100%);
    position: absolute;
    transform: translateY(-0.75rem);
    white-space: nowrap;
    z-index: -1;

    .lineEdge {
      flex: 1;
    }

    .line {
      @include flex($flexDirection: column, $gap: 0.5rem);
      flex: 2;
      border-bottom: 1px solid $black;

      &.neutral {
        border-color: $neutral;

        p {
          color: $neutral;
        }
      }
    }
  }
}

.datesFooter {
  @include flex($gap: 0rem, $alignItems: flex-start);
  padding: 1rem 0;

  .date {
    flex: 1;
    padding: 0 1rem;
    text-align: center;

    .italic {
      font-style: italic;
      color: $neutral;
    }
  }
}
