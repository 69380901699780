@import '../../styles/variables.scss';

.group {
    position: absolute;
    border: 2px solid $primary;
    border-bottom: 0;
    height: .5rem;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    width: 100%;
    top: -1rem;
    display: flex;
    justify-content: center;

    .label {
        position: absolute;
        top: -.8rem;
        font-size: .75rem;
        background-color: white;
        padding: 0 .5rem;
        color: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;

        :global(.MuiSvgIcon-root) {
            width: 1rem;
            height: 1rem;
        }
    }
}