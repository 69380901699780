@import '../../styles/mixins.scss';

.loginPage {
  @include flex($flexDirection: column);
  margin-top: 10rem;

  .signInContainer {
    @include flex($flexDirection: column, $alignItems: flex-start);
    width: 30rem;

    h1 {
      margin-bottom: 1rem;
    }

    .checkBoxContainer {
      height: 2rem;
    }
  }
}
