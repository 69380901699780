@import '../../styles/mixins.scss';

:global(.MuiDataGrid-root) {
  &.table {
    :global(.MuiDataGrid-cell),
    :global(.MuiDataGrid-columnHeader) {
      &:focus {
        outline: unset;
      }
    }

    :global(.MuiDataGrid-cell) {
      :global(.MuiLinearProgress-root) {
        height: 1rem;
        border-radius: $radiusSmall;
      }
    }
  }
}
