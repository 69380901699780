@import '../../styles/mixins.scss';

.missionCard {
  @include flex($justifyContent: space-between, $alignItems: flex-start);
  padding: 1.5rem;

  .left {
    width: 100%;

    .description {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    .italic {
      font-style: italic;
      color: $neutral;
    }
  }

  .right {
    @include flex($flexDirection: column, $justifyContent: space-between, $alignItems: flex-end);
    min-width: 20rem;

    .text {
      color: $neutralDark10;

      &.italic {
        font-style: italic;
        color: $neutralLight10;
      }
    }
  }
}
