@import '../../styles/variables.scss';

.alert {
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 10;

  &.line {
    align-items: center;

    :global(.MuiAlert-message) {
      padding: 0;

      :global(.MuiAlertTitle-root) {
        margin: 0 !important;
        display: flex;
        align-items: center;
      }
    }
  }

  &:not(.line) {
    padding: 1rem !important;
  }

  :global(.MuiAlert-message) {
    overflow: unset;
    text-align: center;
  }

  .button {
    transition: unset !important;
    background-color: unset;
    margin-right: 2rem;

    &:not(:hover) {
      color: $secondaryDark50;
    }
  }
}