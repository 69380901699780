@import '../../../styles/mixins.scss';

.step1 {
  @include flex($flexDirection: column, $alignItems: flex-start);

  .values {
    @include flex();
    width: 100%;
  }

  .questions {
    width: 100%;
    margin: 1rem 0;

    .tradeButtonContainer {
      position: absolute;
      transform: translateY(-2rem);
    }
  }
}

