@import '../../styles/mixins.scss';

.questionValidations {
  @include flex($alignItems: stretch);
  width: 100%;

  .id {
    @include flex($justifyContent: center);
    @include container($backgroundColor: $primaryLight20);
    color: $white;
    width: 3rem;

    .idText {
      transform: rotate(-90deg);
    }
  }

  .questionValidationInfo {
    @include flex($flexDirection: column, $gap: 0.75rem);
    width: 100%;

    .questionInfo {
      @include flex($alignItems: flex-start);
      width: 100%;
      flex-wrap: wrap;

      .imgContainer {
        @include container($borderRadius: $radiusSmall, $border: 2px solid, $borderColor: $neutralLight10);
        @include flex($justifyContent: center, $alignItems: center);
        min-width: 16rem;
        height: 16rem;
        border-radius: $radiusSmall;
        flex: 1;

        img {
          @include size(100%, 100%);
          object-fit: cover;
        }

        .noPhoto {
          font-style: italic;
          color: $neutral;
        }

        &.noImg {
          height: 4rem;
        }
      }

      .questionTable {
        flex: 3;
        min-width: 30rem;

        .questionTableTop {
          @include container($border: 2px solid, $borderColor: $neutral);
          @include flex($gap: 0);
          flex: 1;
          flex-wrap: wrap;

          .questionTitle {
            padding: 1rem;
          }

          .questionInfoSection {
            @include flex($gap: 0, $alignItems: stretch);
            flex-wrap: wrap;

            .questionInfo {
              @include flex($gap: 0, $alignItems: stretch);
              min-width: 26rem;
              flex: 1;

              border: 1px solid $neutralLight20;

              .title {
                @include flex($gap: 0, $alignItems: center);
                flex: 1;
                padding: 0.5rem;
                border-right: 1px dotted $neutralLight20;
              }

              .info {
                @include flex($gap: 0, $alignItems: center);
                flex: 1;
                padding: 0.5rem;

                .noInfo {
                  font-style: italic;
                  color: $neutral;
                }
              }
            }
          }
        }

        .answerOptionsTable {
          @include container($border: 2px solid, $borderColor: $neutral);
          @include flex($gap: 0);
          border-top: none;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
          padding-top: 0.5rem;
          transform: translateY(-0.5rem);
          position: relative;
          z-index: -1;

          h4 {
            @include flex($justifyContent: center);
            width: 15rem;
            min-width: 15rem;
          }

          .answerOption {
            @include flex();
            @include size(100%, 2.5rem);
            border-left: 2px dotted $neutralLight20;
            padding: 0.5rem;

            .choiceId {
              @include container($borderRadius: 1rem);
              @include flex($justifyContent: center);
              @include size(2rem, 2rem);
              background-color: $neutralLight20;
            }
          }
        }
      }
    }

    .validations {
      @include flex($alignItems: stretch);
      width: 100%;

      .validation {
        @include flex($justifyContent: center);
        @include container($backgroundColor: $primaryLight30);
        color: $white;
        width: 3rem;

        .idText {
          transform: rotate(-90deg);
        }
      }

      .validationsInfo {
        @include flex($flexDirection: column, $gap: 0.5rem);
        width: 100%;
      }
    }
  }
}
