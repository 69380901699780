@import '../../styles/mixins.scss';

.storesSummary {
  margin-top: 1rem;

  .mapContainer {
    @include container($borderRadius: $radiusBig, $border: 3px solid, $borderColor: $secondary);
    @include size(100%, 40rem);
    overflow: hidden;
  }

  .storesColumns {
    @include flex();
    margin-top: 1rem;

    .storesColumn {
      @include flex($flexDirection: column);
      flex: 1;

      h4, h5 {
        color: $white;
      }

      .storesHeader {
        @include container($backgroundColor: $neutral, $padding: 0 1rem, $borderColor: $neutralDark20);
        @include flex($justifyContent: space-between);
        @include size(100%, 5rem);

        .pill {
          @include container($borderRadius: 2.5rem, $backgroundColor: $neutralLight20, $padding: 0.5rem 1rem, $border: none);
        }
      }

      .listContainer {
        @include container($backgroundColor: $neutral, $borderColor: $neutralDark20);
        overflow: hidden;
        width: 100%;

        .storesList {
          @include flex($flexDirection: column, $alignItems: flex-start, $gap: 0.5rem);
          @include size(100%, 25rem);
          overflow-y: scroll;
          padding: 1rem;
        }
      }

      &.cantDo {
        .storesHeader,
        .listContainer {
          background-color: $warning;
          border-color: $warningDark20;

          .pill {
            background-color: $warningLight20;
          }
        }
      }

      &.toValidate {
        .storesHeader,
        .listContainer {
          background-color: $primaryLight40;
          border-color: $primary;

          .pill {
            background-color: $primaryLight50;
          }
        }
      }

      &.completed {
        .storesHeader,
        .listContainer {
          background-color: $success;
          border-color: $successDark20;

          .pill {
            background-color: $successLight20;
          }
        }
      }
    }
  }
}
