@import '../../styles/mixins.scss';

.missionStatistics {
  margin-top: 1rem;

  .downloads {
    @include flex();
    margin-bottom: 1rem;
  }
}
