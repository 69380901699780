@import '../../../styles/mixins.scss';

.validations {
  @include flex($flexDirection: column, $alignItems: flex-start, $gap: 2rem);

  .validationContainer {
    @include flex();
    width: 100%;

    .id {
      @include flex();
      @include container($backgroundColor: $primary);
      @include size(3rem, 12rem);
      color: $white;

      .idText {
        @include flex($justifyContent: center);
        @include size(20rem, 5rem);
        position: absolute;
        transform: rotate(-90deg) translateY(-8.75rem);
      }
    }

    .imgContainer {
      @include container($borderRadius: $radiusSmall, $border: 2px solid, $borderColor: $neutralLight10);
      @include size(12rem, 12rem);
      border-radius: $radiusSmall;
      overflow: hidden;

      .img {
        @include size(100%, 100%);
        object-fit: cover;
      }
    }

    .validation {
      @include flex($flexDirection: column, $justifyContent: space-between);
      @include size(100%, 12rem);

      .questionInfo {
        @include container($border: 2px solid, $borderColor: $neutral);
        @include flex($gap: 0);
        @include size(100%, 100%);
        overflow: hidden;

        .questionInfoPartial {
          @include flex($flexDirection: column, $gap: 0);
          @include size(100%, 100%);
          border: 1px solid $neutralLight20;

          .info {
            @include flex();
            @include size(100%, 100%);

            h4 {
              @include flex($justifyContent: center);
              height: 100%;
              width: 15rem;
              border-right: 2px dotted $neutralLight20;
            }

            .italic {
              font-style: italic;
              color: $neutral;
            }

            &.top {
              border-bottom: 1px solid $neutralLight20;
            }
          }
        }
      }

      .inputs {
        @include flex();
        width: 100%;

        .input {
          width: 15rem;
        }
      }
    }
  }

  .noValidations {
    @include flex($flexDirection: column, $justifyContent: center);
    @include size(100%, 100%);
    color: $neutral;
  }
}
