@import '../../styles/mixins.scss';

.pill {
  @include container($borderRadius: 100vh, $padding: 0 1rem, $boxShadow: none);
  @include flex($justifyContent: center, $gap: 0.25rem);
  height: 2.5rem;

  .title {
    width: 100%;

    &.h1 {
      font-weight: 600;
      font-size: 2.5rem;
      letter-spacing: -0.02em;
    }

    &.h2 {
      font-weight: 600;
      font-size: 2rem;
      letter-spacing: -0.02em;
    }

    &.h3 {
      font-weight: 600;
      font-size: 1.5rem;
      letter-spacing: -0.02em;
    }

    &.h4 {
      font-weight: 500;
      font-size: 1.25rem;
    }

    &.h5 {
      font-weight: 500;
      font-size: 1rem;
    }

    &.h6 {
      font-weight: 400;
      font-size: 0.75rem;
      text-transform: uppercase;
    }
  }

  .icon {
    height: 100%;
    object-fit: contain;
    padding: 0.2rem 0;
  }

  // VARIANTS -->
  &.primary {
    background-color: $primaryLight10;
    border-color: $primaryLight10;
    color: $white;

    .icon {
      filter: $filterWhite;
    }
  }

  &.primaryWhite {
    background-color: $white;
    border-color: $primaryLight10;
    color: $primaryLight10;

    .icon {
      filter: $filterPrimaryLight10;
    }
  }

  &.secondary {
    background-color: $secondaryDark10;
    border-color: $secondaryDark10;
    color: $secondaryDark50;

    .icon {
      filter: $filterSecondaryDark50;
    }
  }

  &.secondaryWhite {
    background-color: $white;
    border-color: $secondaryDark50;
    color: $secondaryDark50;

    .icon {
      filter: $filterSecondaryDark50;
    }
  }

  &.neutral {
    background-color: $neutralLight20;
    border-color: $neutralLight20;
    color: $neutralDark30;

    .icon {
      filter: $filterNeutralDark30;
    }
  }

  &.neutralWhite {
    background-color: $white;
    border-color: $neutralDark30;
    color: $neutralDark30;

    .icon {
      filter: $filterNeutralDark30;
    }
  }

  &.success {
    background-color: $successLight10;
    border-color: $successLight10;
    color: $white;

    .icon {
      filter: $filterWhite;
    }
  }

  &.successWhite {
    background-color: $white;
    border-color: $successDark30;
    color: $successDark30;

    .icon {
      filter: $filterSuccessDark30;
    }
  }

  &.danger {
    background-color: $dangerLight10;
    border-color: $dangerLight10;
    color: $white;

    .icon {
      filter: $filterWhite;
    }
  }

  &.dangerWhite {
    background-color: $white;
    border-color: $dangerDark20;
    color: $dangerDark20;

    .icon {
      filter: $filterDangerDark20;
    }
  }

  &.alert {
    background-color: $alertLight10;
    border-color: $alertLight10;
    color: $white;

    .icon {
      filter: $filterWhite;
    }
  }

  &.alertWhite {
    background-color: $white;
    border-color: $alertDark30;
    color: $alertDark30;

    .icon {
      filter: $filterAlertDark30;
    }
  }

  &.warning {
    background-color: $warningLight10;
    border-color: $warningLight10;
    color: $white;

    .icon {
      filter: $filterWhite;
    }
  }

  &.warningWhite {
    background-color: $white;
    border-color: $warningDark20;
    color: $warningDark20;

    .icon {
      filter: $filterWarningDark20;
    }
  }
  // <-- VARIANTS

  // BORDERS -->
  &.noBorderRadiusLeft {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.noBorderRadiusRight {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.noBorderLeft {
    border-left: none;
  }

  &.noBorderRight {
    border-right: none;
  }
  // <-- BORDERS

  // OTHER -->
  &.fullHeight {
    height: 100%;
  }
  // <-- OTHER
}
