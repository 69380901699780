@import '../../styles/mixins.scss';

.dateTimePicker {
  width: 100%;

  &.small {
    :global(.MuiInputBase-input) {
      padding: 8.5px 14px;
    }

    :global(.MuiFormLabel-root:not(.MuiInputLabel-shrink)) {
      -webkit-transform: translate(14px, 8.5px) scale(1);
      -moz-transform: translate(14px, 8.5px) scale(1);
      -ms-transform: translate(14px, 8.5px) scale(1);
      transform: translate(14px, 8.5px) scale(1);
    }
  }

  &.filled {
    fieldset {
      border-color: $primary !important;
      border-width: 2px;
    }
  }
}

.nativeClear {
  padding: 0 !important;
  min-width: 2.5rem !important;
}

.desktopClear {
  position: absolute !important;
  right: 2.5rem;
  padding: 0 !important;
  height: 100%;
  min-width: unset !important;
  transition: unset !important;
  background-color: unset;
  border: unset;
  color: $secondaryDark50 !important;
  z-index: 5;
  display: none;

  
  &:not(.hidden) {
    display: flex;
    align-items: center;
  }

  &:hover:not(:disabled) {
    background-color: unset !important;
    cursor: pointer;
    color: $primary !important;
  }

  
}

.actions {
  > button {
    text-transform: unset !important;
  }
}
