@import '../../styles/mixins.scss';

.buttonElement {
  position: relative;
  height: 2.5rem;

  .button {
    @include flex($justifyContent: center);
    @include font($fontWeight: 500, $fontSize: 1rem);
    height: 100%;
    appearance: none;
    border: 1px solid;
    outline: none;
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    position: relative;
    box-shadow: $bottomShadow;

    .children {
      @include flex($justifyContent: center);
      @include size(100%, 100%);

      .title {
        width: 100%;

        &.h1 {
          font-weight: 600;
          font-size: 2.5rem;
          letter-spacing: -0.02em;
        }

        &.h2 {
          font-weight: 600;
          font-size: 2rem;
          letter-spacing: -0.02em;
        }

        &.h3 {
          font-weight: 600;
          font-size: 1.5rem;
          letter-spacing: -0.02em;
        }

        &.h4 {
          font-weight: 500;
          font-size: 1.25rem;
        }

        &.h5 {
          font-weight: 500;
          font-size: 1rem;
        }

        &.h6 {
          font-weight: 400;
          font-size: 0.75rem;
          text-transform: uppercase;
        }
      }

      .checkbox {
        @include container($borderColor: $neutralLight10, $border: 2px solid);
        height: 100%;
        aspect-ratio: 1 / 1;

        &.checked {
          background-color: $primary;
          border-color: $primary;
        }

        .checkMark {
          @include size(100%, 100%);
          object-fit: contain;
          opacity: 0;
          filter: $filterWhite;

          &.checked {
            opacity: 1;
            color: $white;
          }
        }

        &.disabled {
          cursor: not-allowed;
          background-color: $neutralLight20;
        }
      }
    }

    .focusVisibleIndicator {
      @include size(calc(100% + 9px), calc(100% + 9px));
      position: absolute;
      border: 2px solid;
      opacity: 0;
      z-index: 1;
    }

    &:focus-visible:enabled {
      .focusVisibleIndicator {
        opacity: 1;
      }
    }

    // VARIANTS -->
    &.primary {
      color: $white;
      border-color: $primaryLight10;
      background-color: $primaryLight10;

      .icon {
        filter: $filterWhite;
      }

      .focusVisibleIndicator {
        border-color: $primaryLight10;
      }

      &:hover:enabled {
        border-color: $primary;
        background-color: $primary;
      }

      &:active:enabled {
        border-color: $primaryDark20;
        background-color: $primaryDark20;

        .focusVisibleIndicator {
          border-color: $primaryDark20;
        }
      }

      &.disabled {
        border-color: $primaryLight50;
        background-color: $primaryLight50;
      }
    }

    &.primaryWhite {
      color: $primaryLight10;
      border-color: $primaryLight10;
      background-color: $white;

      .icon {
        filter: $filterPrimaryLight10;
      }

      &:hover:enabled {
        background-color: $primaryLight50;
      }

      &:focus-visible:enabled {
        border: 2px solid $primaryDark30;
      }

      &:active:enabled {
        color: $white;
        background-color: $primary;

        .icon {
          filter: $filterWhite;
        }
      }

      &.disabled {
        color: $primaryLight10;
        border-color: $primaryLight10;
        opacity: 0.5;
      }
    }

    &.secondary {
      color: $black;
      border-color: $secondaryDark10;
      background-color: $secondaryDark10;

      .icon {
        filter: $filterSecondaryDark50;
      }

      .focusVisibleIndicator {
        border-color: $secondaryDark10;
      }

      &:hover:enabled {
        border-color: $secondaryDark30;
        background-color: $secondaryDark30;
      }

      &:active:enabled {
        color: $secondaryDark60;
        border-color: $secondaryDark40;
        background-color: $secondaryDark40;

        .focusVisibleIndicator {
          border-color: $secondaryDark40;
        }
      }

      &.disabled {
        border-color: $secondaryDark10;
        background-color: $secondaryDark10;
        opacity: 0.5;
      }
    }

    &.secondaryWhite {
      color: $secondaryDark50;
      border-color: $secondaryDark50;
      background-color: $white;

      .icon {
        filter: $filterSecondaryDark50;
      }

      &:hover:enabled {
        background-color: $secondary;
      }

      &:focus-visible:enabled {
        border: 2px solid $secondaryDark60;
      }

      &:active:enabled {
        color: white;
        border-color: $secondaryDark50;
        background-color: $secondaryDark50;

        .icon {
          filter: $filterWhite;
        }
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    &.neutral {
      color: $neutralDark30;
      border-color: $neutralLight20;
      background-color: $neutralLight20;

      .icon {
        filter: $filterNeutralDark30;
      }

      .focusVisibleIndicator {
        border-color: $neutralLight20;
      }

      &:hover:enabled {
        border-color: $neutralLight10;
        background-color: $neutralLight10;
      }

      &:active:enabled {
        border-color: $neutral;
        background-color: $neutral;

        .focusVisibleIndicator {
          border-color: $neutral;
        }
      }

      &.disabled {
        color: $neutralDark10;
        opacity: 0.7;

        .icon {
          filter: $filterNeutralDark10;
        }
      }
    }

    &.neutralWhite {
      color: $neutralDark30;
      border-color: $neutralDark30;
      background-color: $white;

      .icon {
        filter: $filterNeutralDark30;
      }

      &:hover:enabled {
        background-color: $neutralLight20;
      }

      &:focus-visible:enabled {
        border: 2px solid $neutralDark30;
      }

      &:active:enabled {
        color: $white;
        border-color: $neutralDark30;
        background-color: $neutralDark20;

        .icon {
          filter: $filterWhite;
        }
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    &.success {
      color: $white;
      border-color: $successLight10;
      background-color: $successLight10;

      .icon {
        filter: $filterWhite;
      }

      .focusVisibleIndicator {
        border-color: $successLight10;
      }

      &:hover:enabled {
        border-color: $success;
        background-color: $success;
      }

      &:active:enabled {
        border-color: $successDark20;
        background-color: $successDark20;

        .focusVisibleIndicator {
          border-color: $successDark20;
        }
      }

      &.disabled {
        border-color: $successLight30;
        background-color: $successLight30;
      }
    }

    &.successWhite {
      color: $successDark30;
      border-color: $successDark30;
      background-color: $white;

      .icon {
        filter: $filterSuccessDark30;
      }

      &:hover:enabled {
        background-color: $successLight30;
      }

      &:focus-visible:enabled {
        border: 2px solid $successDark30;
      }

      &:active:enabled {
        color: $white;
        background-color: $successDark10;

        .icon {
          filter: $filterWhite;
        }
      }

      &.disabled {
        opacity: 0.4;
      }
    }

    &.danger {
      color: $white;
      border-color: $dangerLight10;
      background-color: $dangerLight10;

      .icon {
        filter: $filterWhite;
      }

      .focusVisibleIndicator {
        border-color: $dangerLight10;
      }

      &:hover:enabled {
        border-color: $dangerDark10;
        background-color: $dangerDark10;
      }

      &:active:enabled {
        border-color: $dangerDark30;
        background-color: $dangerDark30;

        .focusVisibleIndicator {
          border-color: $dangerDark30;
        }
      }

      &.disabled {
        border-color: $dangerLight30;
        background-color: $dangerLight30;
      }
    }

    &.dangerWhite {
      color: $dangerDark20;
      border-color: $dangerDark20;
      background-color: $white;

      .icon {
        filter: $filterDangerDark20;
      }

      &:hover:enabled {
        background-color: $dangerLight30;
      }

      &:focus-visible:enabled {
        border: 2px solid $dangerDark30;
      }

      &:active:enabled {
        color: $white;
        background-color: $dangerDark20;

        .icon {
          filter: $filterWhite;
        }
      }

      &.disabled {
        opacity: 0.4;
      }
    }

    &.alert {
      color: $white;
      border-color: $alertLight10;
      background-color: $alertLight10;

      .icon {
        filter: $filterWhite;
      }

      .focusVisibleIndicator {
        border-color: $alertLight10;
      }

      &:hover:enabled {
        border-color: $alertDark10;
        background-color: $alertDark10;
      }

      &:active:enabled {
        border-color: $alertDark30;
        background-color: $alertDark30;

        .focusVisibleIndicator {
          border-color: $alertDark30;
        }
      }

      &.disabled {
        border-color: $alertLight30;
        background-color: $alertLight30;
        opacity: 0.9;
      }
    }

    &.alertWhite {
      color: $alertDark30;
      border-color: $alertDark30;
      background-color: $white;

      .icon {
        filter: $filterAlertDark30;
      }

      &:hover:enabled {
        background-color: $alertLight20;
      }

      &:focus-visible:enabled {
        border: 2px solid $alertDark30;
      }

      &:active:enabled {
        color: $white;
        background-color: $alertDark20;

        .icon {
          filter: $filterWhite;
        }
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    &.warning {
      color: $white;
      border-color: $warningLight10;
      background-color: $warningLight10;

      .icon {
        filter: $filterWhite;
      }

      .focusVisibleIndicator {
        border-color: $warningLight10;
      }

      &:hover:enabled {
        border-color: $warningDark10;
        background-color: $warningDark10;
      }

      &:active:enabled {
        border-color: $warningDark20;
        background-color: $warningDark20;

        .focusVisibleIndicator {
          border-color: $warningDark20;
        }
      }

      &.disabled {
        border-color: $warningLight30;
        background-color: $warningLight30;
      }
    }

    &.warningWhite {
      color: $warningDark20;
      border-color: $warningDark20;
      background-color: $white;

      .icon {
        filter: $filterWarningDark20;
      }

      &:hover:enabled {
        background-color: $warningLight30;
      }

      &:focus-visible:enabled {
        border: 2px solid $warningDark20;
      }

      &:active:enabled {
        color: $white;
        background-color: $warningDark10;

        .icon {
          filter: $filterWhite;
        }
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    &.dropdown {
      background-color: $white;
      border-color: $white;
      color: $black;

      &:hover:enabled {
        background-color: $primaryLight50;
        border-color: $primaryLight50;
        color: $black;

        &:focus {
          background-color: $primaryLight40;
          border-color: $primaryLight40;
          color: $black;
        }
      }

      &.disabled {
        border-color: $neutralLight20;
        color: $neutralLight20;
      }
    }

    &.noContainer {
      border: none;
      background-color: transparent;
      padding: 0;
      color: $neutral;
      animation-name: shortenBack;
      animation-duration: 700ms;
      animation-fill-mode: forwards;
      box-shadow: none;

      .icon {
        filter: $filterPrimary;
      }

      &:hover:enabled {
        color: $primary;
        animation-name: shorten;
        animation-duration: 700ms;
        animation-fill-mode: forwards;

        &:focus {
          color: $primaryDark20;
        }
      }

      &.disabled {
        color: $neutralLight20;
        border-color: $neutralLight20;
      }
    }

    &.link {
      border: none;
      background-color: transparent;
      padding: 0;
      color: $black;
      animation-name: shortenBack;
      animation-duration: 700ms;
      animation-fill-mode: forwards;
      box-shadow: none;

      &:hover:enabled {
        color: $primary;

        &:focus {
          color: $primaryDark20;
        }
      }

      &.disabled {
        color: $neutralLight20;
        border-color: $neutralLight20;
      }
    }
    // <-- VARIANTS

    // BORDERS -->
    &.borderRadiusSmall {
      border-radius: $radiusSmall;

      .focusVisibleIndicator {
        border-radius: $radiusSmall;
      }
    }

    &.borderRadiusMedium {
      border-radius: $radiusMedium;

      .focusVisibleIndicator {
        border-radius: $radiusMedium;
      }
    }

    &.borderRadiusBig {
      border-radius: $radiusBig;

      .focusVisibleIndicator {
        border-radius: $radiusBig;
      }
    }

    &.circle {
      @include size(2.5rem, 2.5rem);
      padding: 0.5rem;
      border-radius: 100%;

      .icon {
        @include size(1.5rem, 1.5rem)
      }

      .focusVisibleIndicator {
        border-radius: 100%;
      }
    }

    &.pill {
      border-radius: 100vh;

      .focusVisibleIndicator {
        border-radius: 100vh;
      }
    }

    &.noBorderRadius {
      border-radius: 0;

      .focusVisibleIndicator {
        border-radius: 0;
      }
    }

    &.noBorderRadiusTop {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .focusVisibleIndicator {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.noBorderRadiusLeft {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .focusVisibleIndicator {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.noBorderRadiusRight {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .focusVisibleIndicator {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.noBorderLeft {
      border-left: none;

      .focusVisibleIndicator {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.noBorderRight {
      border-right: none;

      .focusVisibleIndicator {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    // <-- BORDERS

    // OTHER -->
    &.small {
      padding: 0.25rem 0.25rem;
      height: auto;

      .icon {
        @include size(1rem, 1rem);
      }
    }

    &.textLeft {
      text-align: left;
    }

    &.noShadow {
      box-shadow: none;
    }

    &.disabled {
      cursor: not-allowed;
    }

    &.loading {
      cursor: wait;
    }
    // <-- OTHER

    // ANIMATIONS -->
    .spinner {
      height: 80%;
      animation-name: spin;
      animation-duration: 700ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }

    @keyframes shorten {
      from { transform: translateX(0); }
      to { transform: translateX(0.5rem); }
    }

    @keyframes shortenBack {
      from { transform: translateX(0.5rem); }
      to { transform: translateX(0); }
    }
    // <-- ANIMATIONS
  }

  .tooltip {
    @include container($backgroundColor: $neutralLight10, $padding: 0.5rem, $border: 0);
    @include flex();
    position: absolute;
    top: -2.5rem;
    height: 2.5rem;
    z-index: 10;
    text-wrap: nowrap;

    h5 {
      color: $white;
    }
  }

  &.fullWidth {
    width: 100%;

    .button {
      width: 100%;
    }
  }

  &.fullHeight {
    height: 100%;
  }
}
