@import '../../styles/variables.scss';

.box {
    &.absolute {
        z-index: 1;
        position: absolute;
        right: 0;
        top: 0;
    }

    .toggler {
        display: flex;
        background-color: unset;
        border: unset;
        color: $neutralDark20;
        padding: .5rem;

        &:hover {
            cursor: pointer;
        }
    }
}

.menu {
    :global(.MuiMenuItem-root.Mui-selected) {
        &:not(:hover) {
            background-color: $primaryLight20 !important;
        }

        &:hover {
            background-color: $primary !important;
        }

        &,
        :global(.MuiSvgIcon-root) {
            color: white;
        }
    }
}