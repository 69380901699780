@import '../../styles/variables.scss';

.relative {
    position: relative;
}

table.tooltip {
    border-collapse: collapse;

    &:not(.emphasis) {
        tr:nth-child(1) {
            > td {
                padding-bottom: 1rem;
                font-size: 16px;
            }
        }
    }

    &.emphasis {
        tr {
            td:not(:nth-child(1)):nth-last-child(1) {
                color: $neutralDark20;
            }
        }

        tr:nth-last-child(1) {
            background-color: $neutralDark40;
        }

        tr:nth-child(2) {
            border-bottom: 1px solid $neutralDark20;
            > td {
                padding-bottom: 1rem;
                font-size: 16px;
            }
        }

        tr:nth-child(3) {
            > td {
                padding-top: 1rem;
            }
        }
    
        tr:nth-last-child(2) {
            > td {
                padding-bottom: 1rem;
            }
        }
    }

    td {
        padding: 0.25rem 0.5rem;
        border-radius: $radiusSmall;
    }

    td:not(:first-child) {
        width: 0;
    }
}