@import '../../styles/mixins.scss';

.wizard {
  .wizardHeader {
    @include flex($justifyContent: space-between);
    margin-bottom: 2rem;

    .stepsCounter {
      @include flex($gap: 0.5rem, $justifyContent: flex-end);
      min-width: 12rem;

      .stepsCounterElement {
        @include container($backgroundColor: $primaryLight50, $padding: 0.5rem);
        color: $primary;

        &.active {
          background-color: $primary;
          color: $white;
        }
      }
    }
  }

  .warnings {
    margin-bottom: 2rem;

    .warningText {
      color: $warning;
      margin-Bottom: 1rem;
    }
  }

  .wizardFooter {
    @include flex();
    margin-top: 2rem;
    height: 3rem;
  }
}
