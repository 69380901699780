@import './variables.scss';

@mixin flex($flexDirection: row, $justifyContent: flex-start, $alignItems: center, $gap: 1rem) {
  display: flex;
  flex-direction: $flexDirection;
  justify-content: $justifyContent;
  align-items: $alignItems;
  gap: $gap;
}

@mixin grid($gridTemplateRows, $gridTemplateColumns, $gap: 1rem) {
  display: grid;
  grid-template-rows: $gridTemplateRows;
  grid-template-columns: $gridTemplateColumns;
  gap: $gap;
}

@mixin container($borderRadius: $radiusMedium, $backgroundColor: $white, $boxShadow: $shadow, $padding: 0, $border: 1px solid, $borderColor: $white) {
  border-radius: $borderRadius;
  background-color: $backgroundColor;
  box-shadow: $boxShadow;
  padding: $padding;
  border: $border;
  border-color: $borderColor;
}

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin font($fontFamily: $spaceGrotesk, $fontStyle: normal, $fontWeight: 400, $fontSize: 1rem, $lineHeight: 120%, $letterSpacing: 0em, $color: $neutralDark40) {
  font-family: $fontFamily;
  font-style: $fontStyle;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  color: $color;
}

@mixin link($color: $primary, $colorHover: $primaryLight20, $bottom: -0.25rem, $isUnderlined: true, $isActive: false) {
  text-decoration: none;
  position: relative;
  color: $color;

  @if $isActive {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      bottom: $bottom;
      border-bottom: 1px solid $color;
    }
  }

  &:hover {
    color: $colorHover;

    &:after {
      content: '';
      position: absolute;
      border-bottom: 1px solid $colorHover;
      @if $isUnderlined {
        width: 100%;
      }
      @else {
        width: 0;
      }
      left: 0;
      bottom: $bottom;
    }
  }
}
