@import '../../styles/variables.scss';

.float {
  position: sticky;
  top: 0;
  z-index: 500;
  margin: -1.5rem -2rem;
  padding: 1.5rem 2rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background-color: $neutral;
    opacity: 0;
    height: 1px;
    transition: opacity .3s ease-in-out;
  }

  &.floating {
    background-color: white;

    &::after {
      opacity: 1;
    }
  }
}

:global(.Mui-disabled) {
  &:global(:not(.MuiInputLabel-shrink)) {
    background-color: $secondaryLight10;
  }
}

.clear {
  text-transform: unset !important;
}