@import './mixins.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  @include font($lineHeight: 140%);
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  @include font($fontWeight: 600, $fontSize: 2.5rem, $letterSpacing: -0.02em);
}

h2 {
  @include font($fontWeight: 600, $fontSize: 2rem, $letterSpacing: -0.02em);
}

h3 {
  @include font($fontWeight: 600, $fontSize: 1.5rem, $letterSpacing: -0.02em);
}

h4 {
  @include font($fontWeight: 500, $fontSize: 1.25rem);
}

h5 {
  @include font($fontWeight: 500, $fontSize: 1rem);
}

h6 {
  @include font($fontWeight: 400, $fontSize: 0.75rem);
  text-transform: uppercase;
}

a {
  @include font($fontWeight: 500, $lineHeight: 140%);
  @include link();
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

// Used in the map
.leaflet-container {
  height: 100%;
}

// Used in src/index.tsx
.page {
  @include flex($flexDirection: column, $gap: 2rem);
  width: 100%;
  margin-left: auto;
  padding: 0rem 2rem;
  transition: width 0.5s ease-in-out;

  .content {
    @include flex($flexDirection: column, $alignItems: stretch, $gap: 0);
    min-height: calc(100vh - $footerHeight - $headerHeight - 2rem);
    width: 100%;
  }

  &.loggedIn {
    padding-top: 2rem;

    &.opened {
      width: calc(100% - $sideBarWidthOpen);
    }

    &.closed {
      width: calc(100% - $sideBarWidthClosed);
    }

    .content {
      min-height: calc(100vh - $footerHeight - $headerHeight);
    }
  }
}

// Used in the dashboards
.w-100 {
  width: 100%;
}

.vw-100 {
  width: 100vw;
}

.h-100 {
  height: 100%;
}

.vh-100 {
  height: 100vh;
}

.m-2 {
  margin: 2rem;
}

.my-2 {
  margin: 2rem 0;
}

.mb-2 {
  margin-bottom: 2rem;
}

.keep-ratio {
  aspect-ratio: 1/1;
}
