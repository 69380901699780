@import '../../styles/mixins.scss';

.noPermissions {
  @include flex($flexDirection: column, $justifyContent: center);
  flex: 1;

  .top {
    @include flex($justifyContent: center);
    text-align: center;
    width: 30rem;

    img {
      margin-right: -2rem;
      margin-top: -1rem;
    }
  }
}
