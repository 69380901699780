@import '../../styles/mixins.scss';

.listCard {
  cursor: pointer;
  width: 100%;

  &.grey {
    background-color: $neutralLight20;
  }

  &.hover {
    background-color: $primaryLight50;
  }

  &.focus {
    background-color: $primaryLight40;
  }

  &.box {
    @include container($borderRadius: $radiusHuge);

    &.hover {
      background-color: $neutralLight20;
    }

    &.focus {
      background-color: $neutralLight10;
    }
  }

  &.disabled {
    cursor: default;
  }
}
