@import '../../../styles/mixins.scss';

$transitionTime: 0.5s;

.sidebarContainer {
  @include container($borderRadius: 0, $padding: 0.5rem 0rem 2rem 0rem, $border: 0);
  @include flex($flexDirection: column, $justifyContent: space-between);
  transition: width $transitionTime ease-in-out;
  position: fixed;
  z-index: 10000;

  .sidebarToggleContainer {
    @include container($border: 0, $borderRadius: 0 1.5rem 1.5rem 0, $boxShadow: 4px 0px 4px rgba(0, 0, 0, 0.05));
    @include size(2rem, 3rem);
    cursor: pointer;
    position: absolute;
    z-index: 100;
    top: 0;
    left: $sideBarWidthOpen;
    transition: all $transitionTime ease-in-out;

    img {
      transition: all $transitionTime ease-in-out;
    }
  }

  .sidebarTop {
    @include size(100%, auto);
    @include flex($flexDirection: column, $justifyContent: center, $gap: 0);

    .logoContainer {
      @include container($borderRadius: 0, $boxShadow: 0, $border: 0);
      @include size(5.5rem, 5.5rem);
      cursor: pointer;

      img {
        height: 60%;
      }
    }
  }

  .sidebarBottom {
    @include flex($flexDirection: column);
    width: 100%;

    .profile {
      @include flex();
      @include container($backgroundColor: $primaryLight50, $boxShadow: none, $padding: 0.5rem 1rem, $borderColor: $primaryLight50);
      cursor: pointer;
      width: calc(100% - 2rem);

      .managerInfo {
        @include flex($flexDirection: column, $alignItems: flex-start, $gap: 0);
        width: 100%;
        overflow: hidden;

        p {
          @include font($fontFamily: $redHatDisplay, $fontWeight: 500, $color: $primaryLight10);
          transform: translateY(0.5rem);
          white-space: nowrap;
          transition: transform $transitionTime ease-in-out;
        }

        span {
          @include font($fontFamily: $redHatDisplay, $fontSize: 0.75rem, $color: $primaryLight10);
          color: transparent;
          transition: color 0.3s ease-in-out;
        }

        &.showUserTitle {
          p {
            transform: translateY(0);
          }

          span {
            color: $primaryLight10;
          }
        }
      }
    }
  }

  &.opened {
    @include size($sideBarWidthOpen, 100vh);

    .sidebarToggleContainer {
      transition: transform 0.525s ease-in-out; // little threshold to avoid the gap created between the sidebar and button

      .sidebarToggle {
        transform: translateX(-0.125rem);
      }
    }

    .managerInfo {
      span,
      p {
        max-width: 100%;
      }
    }
  }

  &.closed {
    @include size($sideBarWidthClosed, 100vh);

    .sidebarToggleContainer {
      transform: translateX($sideBarWidthClosed - $sideBarWidthOpen);

      .sidebarToggle {
        transform: rotate(180deg);
      }
    }
  }
}
