@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');

$spaceGrotesk: 'Space Grotesk', sans-serif;
$redHatDisplay: 'Red Hat Display', sans-serif;

$radiusSmall: 0.25rem;
$radiusMedium: 0.5rem;
$radiusBig: 1rem;
$radiusHuge: 3rem;
$shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
$bottomShadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

$headerHeight: 4rem;
$footerHeight: 4rem;
$sideBarWidthOpen: 15.25rem;
$sideBarWidthClosed: 6rem;

$white: #FFFFFF;
$black: #000000;

$primaryDark50: #001C3A;
$primaryDark40: #00356F;
$primaryDark30: #01438C;
$primaryDark20: #014A9B;
$primaryDark10: #0056B4;
$primary: #015EC5;
$primaryLight10: #0367D7;
$primaryLight20: #0072EF;
$primaryLight30: #3E90EB;
$primaryLight40: #6CB2FF;
$primaryLight50: #C2DFFF;

$secondaryDark60: #777770;
$secondaryDark50: #959593;
$secondaryDark40: #B2B1AD;
$secondaryDark30: #D0CFCB;
$secondaryDark20: #DDDCD6;
$secondaryDark10: #EAE9E4;
$secondary: #F0EFEA;
$secondaryLight10: #F5F4EF;
$secondaryLight20: #F7F6F0;
$secondaryLight30: #FAF9F2;
$secondaryLight40: #FFFEF4;
$secondaryLight50: #FFFEFB;

$neutralDark40: #292929;
$neutralDark30: #666666;
$neutralDark20: #888888;
$neutralDark10: #AAAAAA;
$neutral: #CCCCCC;
$neutralLight10: #DDDDDD;
$neutralLight20: #EEEEEE;

$successDark30: #085E0B;
$successDark20: #0E8A12;
$successDark10: #4CB850;
$success: #72CD76;
$successLight10: #82E186;
$successLight20: #A9F5AC;
$successLight30: #D2F5D3;

$dangerDark30: #820000;
$dangerDark20: #C20000;
$dangerDark10: #E10303;
$danger: #FE2B2B;
$dangerLight10: #EB4949;
$dangerLight20: #FF8080;
$dangerLight30: #FFBABA;

$alertDark30: #B78400;
$alertDark20: #E3B100;
$alertDark10: #F6C517;
$alert: #FFD02B;
$alertLight10: #FFDC61;
$alertLight20: #FFEDAC;
$alertLight30: #F5EBC6;

$warningDark30: #883100;
$warningDark20: #D94E00;
$warningDark10: #EC7100;
$warning: #FC8E0D;
$warningLight10: #FFA740;
$warningLight20: #FFC580;
$warningLight30: #FFE7C2;

// The following filters are to be applied over black color to get the desired color
//  To generate new filters, use https://isotropic.co/tool/hex-color-to-css-filter/
//  !! Look at the loss: do not accept the first filter generated, try to get the least loss possible !!
$filterWhite: invert(100%) sepia(0%) saturate(1%) hue-rotate(57deg) brightness(101%) contrast(102%);
$filterBlack: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%) contrast(104%);

$filterPrimaryDark50: invert(10%) sepia(16%) saturate(7491%) hue-rotate(190deg) brightness(92%) contrast(106%);
$filterPrimaryDark40: invert(12%) sepia(98%) saturate(2197%) hue-rotate(200deg) brightness(93%) contrast(101%);
$filterPrimaryDark30: invert(9%) sepia(69%) saturate(6581%) hue-rotate(203deg) brightness(101%) contrast(99%);
$filterPrimaryDark20: invert(15%) sepia(93%) saturate(3339%) hue-rotate(202deg) brightness(84%) contrast(99%);
$filterPrimaryDark10: invert(20%) sepia(52%) saturate(3401%) hue-rotate(200deg) brightness(102%) contrast(101%);
$filterPrimary: invert(22%) sepia(77%) saturate(2750%) hue-rotate(201deg) brightness(94%) contrast(99%);
$filterPrimaryLight10: invert(24%) sepia(75%) saturate(2701%) hue-rotate(201deg) brightness(96%) contrast(98%);
$filterPrimaryLight20: invert(27%) sepia(43%) saturate(4003%) hue-rotate(199deg) brightness(102%) contrast(102%);
$filterPrimaryLight30: invert(52%) sepia(95%) saturate(1253%) hue-rotate(188deg) brightness(89%) contrast(108%);
$filterPrimaryLight40: invert(63%) sepia(10%) saturate(2384%) hue-rotate(176deg) brightness(103%) contrast(104%);

$filterSecondaryDark50: invert(63%) sepia(5%) saturate(88%) hue-rotate(21deg) brightness(94%) contrast(83%);
$filterSecondaryDark40: invert(83%) sepia(9%) saturate(88%) hue-rotate(11deg) brightness(85%) contrast(87%);
$filterSecondaryDark30: invert(95%) sepia(7%) saturate(102%) hue-rotate(11deg) brightness(86%) contrast(93%);
$filterSecondaryDark20: invert(88%) sepia(3%) saturate(272%) hue-rotate(14deg) brightness(100%) contrast(94%);
$filterSecondaryDark10: invert(99%) sepia(24%) saturate(127%) hue-rotate(331deg) brightness(96%) contrast(90%);
$filterSecondary: invert(99%) sepia(3%) saturate(416%) hue-rotate(327deg) brightness(95%) contrast(97%);
$filterSecondaryLight10: invert(94%) sepia(86%) saturate(21%) hue-rotate(350deg) brightness(100%) contrast(93%);
$filterSecondaryLight20: invert(100%) sepia(1%) saturate(1086%) hue-rotate(339deg) brightness(99%) contrast(95%);
$filterSecondaryLight30: invert(94%) sepia(7%) saturate(174%) hue-rotate(357deg) brightness(105%) contrast(96%);
$filterSecondaryLight40: invert(91%) sepia(1%) saturate(1609%) hue-rotate(342deg) brightness(107%) contrast(106%);
$filterSecondaryLight50: invert(92%) sepia(1%) saturate(1271%) hue-rotate(330deg) brightness(107%) contrast(104%);

$filterNeutralDark40: invert(22%) sepia(0%) saturate(8%) hue-rotate(188deg) brightness(89%) contrast(112%);
$filterNeutralDark30: invert(39%) sepia(0%) saturate(0%) hue-rotate(215deg) brightness(100%) contrast(93%);
$filterNeutralDark20: invert(62%) sepia(1%) saturate(0%) hue-rotate(136deg) brightness(87%) contrast(87%);
$filterNeutralDark10: invert(68%) sepia(0%) saturate(1%) hue-rotate(4deg) brightness(99%) contrast(96%);
$filterNeutral: invert(94%) sepia(0%) saturate(0%) hue-rotate(242deg) brightness(90%) contrast(86%);
$filterNeutralLight10: invert(97%) sepia(0%) saturate(66%) hue-rotate(173deg) brightness(90%) contrast(98%);
$filterNeutralLight20: invert(100%) sepia(0%) saturate(3419%) hue-rotate(55deg) brightness(118%) contrast(87%);

$filterSuccessDark30: invert(18%) sepia(82%) saturate(1509%) hue-rotate(99deg) brightness(102%) contrast(94%);
$filterSuccessDark20: invert(35%) sepia(99%) saturate(521%) hue-rotate(75deg) brightness(96%) contrast(104%);
$filterSuccessDark10: invert(59%) sepia(45%) saturate(697%) hue-rotate(73deg) brightness(98%) contrast(74%);
$filterSuccess: invert(74%) sepia(15%) saturate(1143%) hue-rotate(73deg) brightness(94%) contrast(92%);
$filterSuccessLight10: invert(90%) sepia(6%) saturate(3274%) hue-rotate(62deg) brightness(94%) contrast(87%);
$filterSuccessLight20: invert(83%) sepia(6%) saturate(1916%) hue-rotate(72deg) brightness(111%) contrast(92%);
$filterSuccessLight30: invert(87%) sepia(14%) saturate(432%) hue-rotate(72deg) brightness(109%) contrast(92%);

$filterDangerDark30: invert(14%) sepia(32%) saturate(7135%) hue-rotate(350deg) brightness(82%) contrast(119%);
$filterDangerDark20: invert(12%) sepia(66%) saturate(6400%) hue-rotate(357deg) brightness(86%) contrast(115%);
$filterDangerDark10: invert(11%) sepia(99%) saturate(4851%) hue-rotate(8deg) brightness(97%) contrast(119%);
$filterDanger: invert(27%) sepia(94%) saturate(3698%) hue-rotate(346deg) brightness(99%) contrast(100%);
$filterDangerLight10: invert(40%) sepia(25%) saturate(6238%) hue-rotate(335deg) brightness(97%) contrast(90%);
$filterDangerLight20: invert(60%) sepia(67%) saturate(688%) hue-rotate(311deg) brightness(104%) contrast(107%);
$filterDangerLight30: invert(75%) sepia(58%) saturate(2859%) hue-rotate(308deg) brightness(139%) contrast(113%);

$filterAlertDark30: invert(52%) sepia(16%) saturate(2911%) hue-rotate(12deg) brightness(94%) contrast(101%);
$filterAlertDark20: invert(60%) sepia(92%) saturate(710%) hue-rotate(8deg) brightness(99%) contrast(100%);
$filterAlertDark10: invert(82%) sepia(66%) saturate(1202%) hue-rotate(343deg) brightness(97%) contrast(98%);
$filterAlert: invert(69%) sepia(99%) saturate(383%) hue-rotate(349deg) brightness(107%) contrast(101%);
$filterAlertLight10: invert(87%) sepia(88%) saturate(488%) hue-rotate(322deg) brightness(102%) contrast(102%);
$filterAlertLight20: invert(85%) sepia(39%) saturate(328%) hue-rotate(348deg) brightness(104%) contrast(102%);
$filterAlertLight30: invert(94%) sepia(16%) saturate(389%) hue-rotate(357deg) brightness(97%) contrast(97%);

$filterWarningDark30: invert(13%) sepia(90%) saturate(4714%) hue-rotate(40deg) brightness(88%) contrast(101%);
$filterWarningDark20: invert(33%) sepia(65%) saturate(1813%) hue-rotate(2deg) brightness(93%) contrast(104%);
$filterWarningDark10: invert(48%) sepia(99%) saturate(3173%) hue-rotate(11deg) brightness(102%) contrast(101%);
$filterWarning: invert(60%) sepia(9%) saturate(7348%) hue-rotate(355deg) brightness(103%) contrast(98%);
$filterWarningLight10: invert(69%) sepia(87%) saturate(478%) hue-rotate(327deg) brightness(100%) contrast(104%);
$filterWarningLight20: invert(81%) sepia(21%) saturate(1109%) hue-rotate(330deg) brightness(103%) contrast(102%);
$filterWarningLight30: invert(90%) sepia(2%) saturate(2982%) hue-rotate(344deg) brightness(92%) contrast(130%);
