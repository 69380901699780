@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/responsive.scss";

.card {
    height: 100%;
    position: relative;
}

.pointer {
    cursor: pointer;
}

.content {
    @include flex($flexDirection: column, $justifyContent:center, $gap: 0.5rem);
    height: 100%;

    .value {
        line-height: 1;
        text-align: center;

        &:not(.string) {
            font-size: 2vw;
        }

        &.string {
            font-size: 1vw;
        }

        @include smallScreenBreakpoint() {
            &:not(.string) {
                font-size: 4vw;
            }
    
            &.string {
                font-size: 2vw;
            }
        }
    }
}

.comparison-box {
    &.positive {
        > *:not(span) {
            color: #84BB4C;
        }
    }

    &.negative {
        > *:not(span) {
            color: #ED6E6E;
        }
    }

    .icon {
        width: 1rem;
        height: 1rem;
    }

    span {
        font-size: .75rem;
    }
}

.label {
    @include flex($flexDirection: row, $gap: 0.5rem);
    text-align: center;
}

.info {
    font-size: 1.25rem !important;
    color: $secondaryDark50 !important;

    &:hover {
        color: $primaryLight30 !important;
        cursor: pointer;
    }
}

.alert {
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  z-index: 10;
}