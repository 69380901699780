@import '../../../styles/mixins.scss';

.header {
  @include flex();
  @include size(100%, $headerHeight);
  border-top: 1px solid $neutralLight20;
  padding: 0 2rem;

  .logoContainer {
    height: 80%;
    outline: none;
    appearance: none;
    border: none;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    .logo {
      height: 80%;
    }
  }
}
