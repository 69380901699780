@import '../../styles/mixins.scss';

.imageUploader {
  @include size(100%, 100%);
  @include container($borderRadius: $radiusSmall, $border: 2px solid, $borderColor: $neutralLight10);
  position: relative;
  overflow: hidden;

  .imageUploaderBox {
    @include size(100%, calc(100% - 3rem));
    @include flex($justifyContent: center);
    text-align: center;
    cursor: pointer;
    position: relative;

    p {
      padding: 1rem;
    }

    .imgContainer {
      @include size(100%, 100%);
      border-radius: $radiusSmall $radiusSmall 0 0;
      overflow: hidden;

      img {
        @include size(100%, 100%);
        object-fit: cover;
      }
    }
  }

  .buttonContainer {
    @include size(100%, 3rem);
    border-radius: 0 0 $radiusSmall $radiusSmall;
    position: absolute;
    bottom: 0;
  }
}
