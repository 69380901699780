@import '../../styles/mixins.scss';

.missions {
  .top {
    @include flex($justifyContent: space-between);
  }

  .missionFilter {
    margin-top: 1rem;
  }

  .missionsList {
    margin-top: 1rem;
  }

  .noMissions {
    margin-top: 5rem;
    text-align: center;
  }
}
