@import '../../styles/mixins.scss';

.absoluteButton {
  @include flex($justifyContent: center);
  @include size(3rem, 3rem);
  @include container($borderRadius: 100%, $backgroundColor: $white, $borderColor: $primary);
  border-color: $primary;
  cursor: pointer;

  .img {
    @include size(2rem, 2rem);
    filter: $filterPrimary;
  }

  &.hover {
    background-color: $primaryLight50;
    scale: 1.2;
    transform: translateY(-0,4rem);
  }

  &.focus {
    background-color: $primaryLight40;
  }
}
