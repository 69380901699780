@import '../../styles/mixins.scss';

$topHeight: 21rem;

.question {
  @include flex($alignItems: stretch);
  margin-top: 1.5rem;

  .id {
    @include flex($justifyContent: center);
    @include container($backgroundColor: $primary);
    width: 3rem;
    color: $white;

    .idText {
      transform: rotate(-90deg);
      white-space: nowrap;
    }
  }

  .photo {
    @include container($borderColor: $neutralLight20);
    height: $topHeight;
    flex: 1;
  }

  .rightSide {
    flex: 3;

    .top {
      @include flex($flexDirection: column, $justifyContent: space-between);
      height: $topHeight;

      .row {
        @include flex($justifyContent: space-between);
        @include size(100%, 5rem);

        .semiRow {
          @include flex($justifyContent: space-between, $gap: 0);
          @include size(50%, 100%);

          &.gap {
            gap: 1rem;
          }
        }
      }
    }

    .bottom {
      @include flex($flexDirection: column);
      margin-top: 1rem;
      text-align: start;

      .choice {
        @include flex();
        width: 100%;

        .choiceId {
          @include flex($justifyContent: center);
          @include container($backgroundColor: $primary);
          @include size(3rem, 3rem);
          color: $white;
        }
      }
    }
  }
}
