@import '../../styles/mixins.scss';

.answerCard {
  @include flex($flexDirection: column, $alignItems: flex-start, $gap: 0.5rem);
  padding: 1.5rem;

  .header {
    @include flex($justifyContent: space-between);
    width: 100%;

    .arrow {
      @include size(2rem, 2rem);
    }
  }

  .subHeader{
    @include flex($justifyContent: space-between);
    @include size(100%, 1.5rem);
    padding-right: 2.5rem;

    .subHeaderPartial {
      @include flex();
      height: 100%;
    }
  }

  .questions {
    @include flex($justifyContent: space-between);
    flex-wrap: wrap;
    padding: 0 1rem;
    width: 100%;
    max-height: 25rem;
    overflow-y: scroll;

    .question {
      @include container($borderRadius: $radiusBig, $boxShadow: none, $padding: 1rem, $borderColor: $neutral);
      @include flex($justifyContent: space-between, $alignItems: flex-start);
      @include size(calc(50% - 0.5rem), 7.5rem);
      overflow: hidden;

      .left {
        flex: 4;

        p {
          color: $neutralDark20;
        }

        .answerContainer {
          @include flex($gap: 0.5rem, $alignItems: flex-start);

          .answers {
            margin-top: 0.25rem;
          }
        }
      }

      .photoIcon {
        height: 2rem;
        object-fit: contain;
      }

      .photosContainer {
        flex: 1;
        min-height: 5rem;
      }
    }

    &.open {
      max-height: 50rem;

      .question {
        width: 100%;
        height: auto;
      }
    }
  }

  .cantDoContainer {
    @include flex($justifyContent: space-between, $alignItems: flex-start);
    width: 100%;

    .cantDoErrors {
      @include flex($flexDirection: column, $alignItems: flex-start);
      margin-top: 1rem;

      .errorPillText {
        @include flex($gap: 0);
      }
    }

    .photosContainer {
      height: 10rem;
    }
  }


  .validationButtons {
    @include flex($justifyContent: flex-end, $gap: 0.5rem);
    margin-top: 1rem;
    width: 100%;
  }
}
