@import '../../styles/mixins.scss';

.checkboxContainer {
  @include flex();
  height: 70%;

  .checkbox {
    @include container($borderColor: $neutralLight10, $border: 2px solid);
    height: 1.75rem;
    aspect-ratio: 1 / 1;
    cursor: pointer;

    &.checked {
      background-color: $primary;
      border-color: $primary;
    }

    .checkMark {
      @include size(100%, 100%);
      object-fit: contain;
      opacity: 0;
      filter: $filterWhite;

      &.checked {
        opacity: 1;
        color: $white;
      }
    }

    &.disabled {
      cursor: not-allowed;
      background-color: $neutralLight20;
    }

    &.fullHeight {
      height: 100%;
    }
  }

  .textDisabled {
    color: $neutralLight10;
  }
}
