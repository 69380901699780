@import '../../styles/mixins.scss';

.routeNotFound {
  @include flex($flexDirection: column, $justifyContent: center);
  flex: 1;
  width: 40rem;
  margin-left: 50%;
  transform: translateX(-50%);

  .top {
    @include flex($justifyContent: center);

    img {
      margin-right: -2rem;
      margin-top: -1rem;
    }

    h2 {
      padding-right: 2rem;
    }
  }
}
