@import '../../styles/mixins.scss';

.continuousPill {
  @include flex($gap: 0);
  height: 2.5rem;

  &.fullHeight {
    height: 100%;
  }
}
